import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import SEO from 'components/seo'
import PropertyGridResults from 'components/propertyGridResults'

export default ({ data }) => {
	const {
		title,
		buyRentPageFields
	} = data.siteFields.page

	return (
		<>
			<SEO title={title} />
			<PropertyGridResults
				{...buyRentPageFields}
			/>
		</>
	)
}

export const query = graphql`
  	query($databaseId: ID!) {
		siteFields {
			page(id: $databaseId, idType: DATABASE_ID) {
				databaseId
				title
				date
				buyRentPageFields {
					heroHeading
					heroBackground {
						sourceUrl
					}
				}
			}
		}
  	}
`
